/* global BigInt */
import { useState, useEffect } from "react";
import Web3 from 'web3';
import bondingCurveABI from '../abi/bondingCurve.json';
import { RPC_URL } from "../_const";

export const useTokenSwap = () => {
    const [usdcAmountBuySide, setUsdcAmountBuySide] = useState(0);
    const [usdcAmountSellSide, setUsdcAmountSellSide] = useState(0);
    const [saeAmountBuySide, setSaeAmountBuySide] = useState(0);
    const [saeAmountSellSide, setSaeAmountSellSide] = useState(0);

    useEffect(async () => {
        console.log("saeAmountSellSide: ", saeAmountSellSide);
        if (saeAmountSellSide <= 0)
            return setUsdcAmountSellSide(0)
        try {
            const web3 = new Web3(RPC_URL)
            const contract = new web3.eth.Contract(bondingCurveABI, '0xBe28b747c80824A4EEF6a8c49B7fF787EFf0456e');
            console.log("contract saeAmountSellSide : ", contract);
            let price = await contract.methods.calculatePriceForSell(BigInt(saeAmountSellSide * 1e18).toString()).call()
            console.log("price saeAmountSellSide : ", price);
            setUsdcAmountSellSide(price / 1e6);
        } catch (error) {
            console.log("error saeAmountSellSide : ", error);
        }
    }, [saeAmountSellSide]);

    useEffect(async () => {
        if (usdcAmountBuySide <= 0)
            return setSaeAmountBuySide(0)
        try {
            const web3 = new Web3(RPC_URL)
            const contract = new web3.eth.Contract(bondingCurveABI, '0xBe28b747c80824A4EEF6a8c49B7fF787EFf0456e');
            console.log("contract: ", contract);
            let price = await contract.methods.calculatePriceForBuy(BigInt(usdcAmountBuySide * 1e6).toString()).call()
            console.log("price: ", price);
            setSaeAmountBuySide(price / 1e18);
        } catch (error) {
            console.log("error: ", error);
        }
    }, [usdcAmountBuySide]);

    return {
        usdcAmountBuySide,
        setUsdcAmountBuySide,
        usdcAmountSellSide,
        setUsdcAmountSellSide,
        saeAmountBuySide,
        setSaeAmountBuySide,
        saeAmountSellSide,
        setSaeAmountSellSide
    }
};