import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Web3 from 'web3';
import bondingCurveABI from '../../abi/bondingCurve.json';
import { CONTRACT_ADDRESS, PRIVATE_SELL_SUPPLY, RPC_URL } from "../../_const";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const ProgressBar = () => {
    const [result, setResult] = useState(0);
    useEffect(async () => {
        const web3 = new Web3(RPC_URL);
        const contract = new web3.eth.Contract(bondingCurveABI, CONTRACT_ADDRESS);
        const totalSupply = await contract.methods.totalSupply().call();
        console.log("totalSupply: ", totalSupply);
        const completedPercentage = ((totalSupply / 1e18) * 100) / PRIVATE_SELL_SUPPLY;
        setResult(completedPercentage);
    }, []);
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <BorderLinearProgress variant="determinate" value={result} style={{ flexGrow: 1 }} />
            <span style={{
                marginLeft: '10px',
                color: 'white'
            }}>
                {`${result.toFixed(2)}%`}
            </span>
        </div>
    );
};

export default ProgressBar;