import { useEffect, useState } from 'react';
import './App.css';

import BalanceModal from './components/BalanceModal';
import TransactionModal from './components/TransactionModal';

import {
  containerStyle,
  buttonStyle,
  leftStatus,
  statusIconConnected,
  statusIconDisconnected,
  rowStyle
} from './styles/styles'
import TokenSwap from './components/TokenSwap/TokenSwap';
import { useMetamask } from './hooks/useMetamask';

// Sometimes using Web3 packages like Web3 are just not ideal and you need a simple solution to work with MetaMask. Here are some simple solutions and code snippets you can use
// to get rolling on better understanding how to make simple transactions with MetaMask wallet directly using window.ethereum

function App() {

  const [walletAccount, setWalletAccount] = useState('')
  const [currentChain, setCurrentChain] = useState('')
  const [showBalanceModal, setShowBalanceModal] = useState(false)
  const [showTransactionModal, setShowTransactionModal] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [ethBalance, setEthBalance] = useState(null)


  const test = useMetamask();
  console.log("test: ", test);

  // Used to see if the wallet is currently connected to the application
  // If an account has been accessed with MetaMask, then the wallet is connected to the application.
  useEffect(() => {
    setIsConnected(walletAccount ? true : false)
  }, [walletAccount])

  // Handle Disconnected. Removing the state of the account connected 
  // to your app should be enough to handle Disconnect with your application.
  const handleDisconnect = async () => {

    console.log('Disconnecting MetaMask...')
    setIsConnected(false)
    setWalletAccount('')
  }

  // Connect Once and set the account. 
  // Can be used to trigger a new account request each time, 
  // unlike 'eth_requestAccounts'
  const handleConnectOnce = async () => {

    const accounts = await window.ethereum.request({
      method: 'wallet_requestPermissions',
      params: [{
        eth_accounts: {}
      }]
    }).then(() => window.ethereum.request({ method: 'eth_requestAccounts' }))

    setWalletAccount(accounts[0])

  }


  return (
    <div className="App" style={{ background: '#121619' }}>
      <div className="container" style={containerStyle}>


        <TokenSwap />

      </div>
    </div>
  );
}

export default App;


