import Web3 from "web3";
import bondingCurveABI from '../abi/bondingCurve.json';
import usdcABI from '../abi/usdcabi.json';

import { useState } from "react";
import { CONTRACT_ADDRESS, USDC_CONTRACT_ADDRESS } from "../_const";

export const useSwap = (usdcAmount, publicKey) => {
    console.log("publicKey: ", publicKey);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const buyTransaction = async () => {
        try {
            const web3 = new Web3(window.ethereum);
            const contract = new web3.eth.Contract(bondingCurveABI, CONTRACT_ADDRESS, { from: publicKey });
            const contractUSDC = new web3.eth.Contract(usdcABI, USDC_CONTRACT_ADDRESS, { from: publicKey });
            let alreadyAllowedTokens = await contractUSDC.methods.allowance(publicKey, CONTRACT_ADDRESS).call()
            alreadyAllowedTokens = alreadyAllowedTokens > 0 ? alreadyAllowedTokens / 1e6 : alreadyAllowedTokens;

            console.log("alreadyAllowedTokens: ", alreadyAllowedTokens);
            console.log("usdcAmount: ", usdcAmount);
            const nonce = await web3.eth.getTransactionCount(publicKey, 'latest');
            if (!localStorage.getItem('alreadyApproved') || alreadyAllowedTokens < usdcAmount) {
                const approveContract = new web3.eth.Contract(usdcABI, undefined, { from: publicKey });
                const approval = await approveContract.methods.approve(CONTRACT_ADDRESS, web3.utils.toHex('100000000'));
                console.log("nonce: ", nonce);
                await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [{
                        from: publicKey,
                        to: USDC_CONTRACT_ADDRESS,
                        gas: '0x7A120',
                        gasPrice: web3.utils.toHex(144000000000),
                        value: '0x0',
                        data: approval.encodeABI()
                    }]
                })
                localStorage.setItem('alreadyApproved', true);
            }
            const data = contract.methods._buy(usdcAmount * 1e6);
            console.log("usdcAmount: ", usdcAmount);
            console.log("data: ", data);
            await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: publicKey,
                    to: CONTRACT_ADDRESS,
                    gas: '0x7A120',
                    gasPrice: web3.utils.toHex(144000000000),
                    value: '0x0',
                    data: data.encodeABI()
                }]
            }).then((result) => {
                console.log("result: ", result);
                setShowSuccessModal(true);
            })
        } catch (error) {
            console.log("error buyTransaction: ", error);

        }

    }

    return {
        buyTransaction,
        showSuccessModal,
        setShowSuccessModal
    }
}