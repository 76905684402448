import { useState } from "react";
import { RPC_URL } from "../_const";

export const useMetamask = () => {
    const [selectedAccount, setSelectedAccount] = useState([]);
    const [currentChain, setCurrentChain] = useState(0);
    try {
        let provider = window.ethereum;
        provider.request({ method: 'eth_requestAccounts' }).then((result) => {
            setSelectedAccount(result[0]);
        });

        if (window.ethereum.networkVersion !== '0xaa36a7') {
            try {
                window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0xaa36a7' }]
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainName: 'Sepolia',
                                chainId: '0xaa36a7',
                                nativeCurrency: { name: 'ETHER', decimals: 18, symbol: 'ETH' },
                                rpcUrls: [RPC_URL]
                            }
                        ]
                    });
                }
            }
        }

        window.ethereum.on('chainChanged', (chaindId) => {
            setCurrentChain(chaindId);
        });
        window.ethereum.on('accountsChanged', function (accounts) {
            setSelectedAccount(accounts[0]);
        });

    } catch (error) {
        console.log("error useMetamask: ", error);
    }
    return {
        selectedAccount,
        currentChain
    }
}