/* global BigInt */
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Box, Button, TextField, Typography, Select, MenuItem, Container } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';
import { useTokenSwap } from '../../hooks/useTokenSwap';
import { useSwap } from '../../hooks/useSwap';
import { useMetamask } from '../../hooks/useMetamask';
import { formatAddress } from '../../Utils';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ProgressBar from '../ProgressBar/ProgressBar';
import { ViewSwitch } from '../Timer/ViewSwitch';
import { IconButton, Tabs, Tab } from '@mui/material';
import { useSell } from '../../hooks/useSell';

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UniswapInterface() {
    const provider = window.ethereum;
    const { usdcAmountBuySide,
        setUsdcAmountBuySide,
        usdcAmountSellSide,
        saeAmountBuySide,
        saeAmountSellSide,
        setSaeAmountSellSide
    } = useTokenSwap();
    const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(true);
    const { selectedAccount } = useMetamask();
    const { sellTransaction } = useSell(saeAmountSellSide, selectedAccount);
    const { buyTransaction, showSuccessModal, setShowSuccessModal } = useSwap(usdcAmountBuySide, selectedAccount);
    const isSelectedAccountAvailable = Array.isArray(selectedAccount) ? 'Not connected' : typeof selectedAccount === 'string' ? formatAddress(selectedAccount) : 'Not connected';
    const [isReversed, setIsReversed] = useState(false);
    const [tabValue, setTabValue] = useState('buy');


    useEffect(() => {
        if (showSuccessModal)
            setTimeout(() => {
                setShowSuccessModal(false)
            }, 3000);
    }, [showSuccessModal]);

    useEffect(() => {
        if (typeof provider === 'undefined')
            setIsMetamaskInstalled(false);
    }, [provider]);

    const handleSwap = () => {
        setIsReversed(!isReversed);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const isBuyTab = tabValue === 'buy';

    console.log("saeAmountSellSide: ", saeAmountSellSide);
    console.log("usdcAmountBuySide: ", usdcAmountBuySide);
    return (
        <div style={{ background: '#12151A', height: '100vh', position: 'relative', width: '100%' }}>
            <AppBar position="sticky" style={{ background: 'transparent', boxShadow: 'none', top: 0 }}>
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: '50px', marginRight: '20px' }} src="https://plasticapp.io/wp-content/uploads/2023/10/logo-fond-transparent-1.png" alt="PlasticApp logo" />

                        <Button color="inherit">Swap</Button>
                        <Button color="inherit">Shop (soon)</Button>
                        <Button color="inherit">Pools (soon)</Button>
                    </div>

                    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <img width={'40px'} src="https://cdn.discordapp.com/attachments/524581791227576351/1236619998340976680/polygon-matic-icon3725.logowik.com-removebg-preview.png?ex=6638ab9e&is=66375a1e&hm=a89a988e6a7bab02e972397403b1c82fff25d52fb4f556e21f2b769c3a711c25&" alt="Polygon Icon" />
                        <Typography variant="body1" color="inherit">{isSelectedAccountAvailable}</Typography>
                    </div>
                </Toolbar>
            </AppBar>

            <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 'calc(100vh - 64px)', width: '70%' }}>

                <ViewSwitch />
                <ProgressBar style={{
                    position: 'absolute',
                    top: 'calc(50% - 50px)',
                    left: '15%',
                    right: '15%',
                    zIndex: 10
                }} />
                <Box
                    style={{
                        border: '1px solid #2F3340',
                        borderRadius: '8px',
                        padding: '20px',
                        background: '#1D2027',
                        width: '80%',
                        margin: '0 auto',
                        marginTop: '40px',
                        boxShadow: '0px 10px 30px rgb(102,200,100)'
                    }}
                >
                    <Typography variant="h6" align="center" style={{ marginBottom: '20px' }}>
                        Swap
                    </Typography>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} textColor="inherit" indicatorColor="primary">
                            <Tab value="buy" label="Buy" />
                            <Tab value="sell" label="Sell" />
                        </Tabs>
                    </Box>
                    <Box display="flex" flexDirection="column" gap={2}>
                        {isBuyTab ? (
                            <>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    placeholder={usdcAmountBuySide}
                                    value={usdcAmountBuySide}
                                    onChange={(e) => setUsdcAmountBuySide(parseFloat(e.target.value))}
                                    InputProps={{
                                        endAdornment: <Select value="USDC" style={{ color: 'white' }}>
                                            <MenuItem value="USDC">USDC</MenuItem>
                                        </Select>,
                                        style: { color: 'white' }
                                    }}
                                    style={{ background: '#2F3340', color: 'white' }}
                                />
                                <IconButton onClick={handleSwap} style={{ alignSelf: 'center' }}>
                                    <ArrowDownward color="primary" />
                                </IconButton>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    disabled={true}
                                    placeholder={saeAmountBuySide}
                                    InputProps={{
                                        endAdornment: <Select value="SAE" style={{ color: 'white' }}>
                                            <MenuItem value="SAE">SAE</MenuItem>
                                        </Select>,
                                        style: { color: 'white' }
                                    }}
                                    value={saeAmountBuySide}
                                    style={{ background: '#2F3340', color: 'white' }}
                                />
                            </>
                        ) : (
                            <>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <Select value="SAE" style={{ color: 'white' }}>
                                            <MenuItem value="SAE">SAE</MenuItem>
                                        </Select>,
                                        style: { color: 'white' }
                                    }}
                                    onChange={(e) => setSaeAmountSellSide(parseFloat(e.target.value))}
                                    value={saeAmountSellSide}
                                    style={{ background: '#2F3340', color: 'white' }}
                                />
                                <IconButton onClick={handleSwap} style={{ alignSelf: 'center' }}>
                                    <ArrowDownward color="primary" />
                                </IconButton>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    disabled={true}
                                    placeholder={usdcAmountSellSide}
                                    value={usdcAmountSellSide}
                                    InputProps={{
                                        endAdornment: <Select value="USDC" style={{ color: 'white' }}>
                                            <MenuItem value="USDC">USDC</MenuItem>
                                        </Select>,
                                        style: { color: 'white' }
                                    }}
                                    style={{ background: '#2F3340', color: 'white' }}
                                />
                            </>
                        )}
                    </Box>

                    {
                        isMetamaskInstalled ?
                            <Button onClick={isBuyTab ? buyTransaction : sellTransaction} disabled={isBuyTab ? usdcAmountBuySide <= 0 : saeAmountSellSide <= 50} variant="contained" color="primary" style={{ marginTop: '20px' }}>
                                Confirm
                            </Button> :
                            <Button disabled={true} variant="contained" color="primary" style={{ marginTop: '20px' }}>
                                Install Metamask
                            </Button>
                    }

                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showSuccessModal} autoHideDuration={6000}>
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Transaction successful
                        </Alert>
                    </Snackbar>
                </Box>
            </Container>
        </div>
    );
}
