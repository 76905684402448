import Web3 from "web3";
import bondingCurveABI from '../abi/bondingCurve.json';
import usdcABI from '../abi/usdcabi.json';


import { useState } from "react";
import { CONTRACT_ADDRESS, USDC_CONTRACT_ADDRESS } from "../_const";
import { BigNumber } from "ethers";

export const useSell = (saeAmount, publicKey) => {
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const sellTransaction = async () => {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(bondingCurveABI, CONTRACT_ADDRESS, { from: publicKey });
        const contractUSDC = new web3.eth.Contract(usdcABI, USDC_CONTRACT_ADDRESS, { from: publicKey });
        let alreadyAllowedTokens = await contract.methods.allowance(publicKey, CONTRACT_ADDRESS).call();

        alreadyAllowedTokens = alreadyAllowedTokens > 0 ? alreadyAllowedTokens / 1e18 : alreadyAllowedTokens;
        if (!localStorage.getItem('alreadyApproved') || alreadyAllowedTokens < saeAmount) {
            const approveContract = new web3.eth.Contract(bondingCurveABI, undefined, { from: publicKey });
            const approval = await approveContract.methods.approve(CONTRACT_ADDRESS, web3.utils.toHex('10000000000000'));
            await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: publicKey,
                    to: CONTRACT_ADDRESS,
                    gas: '0x7A120',
                    gasPrice: web3.utils.toHex(144000000000),
                    value: '0x0',
                    data: approval.encodeABI()
                }]
            })
            localStorage.setItem('alreadyApproved', true);
        }
        const data = contract.methods.sell(web3.utils.toWei(saeAmount.toString(), 'ether'));
        await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [{
                from: publicKey,
                to: CONTRACT_ADDRESS,
                gas: '0x7A120',
                gasPrice: web3.utils.toHex(144000000000),
                value: '0x0',
                data: data.encodeABI()
            }]
        }).then((result) => {
            console.log("result: ", result);
            setShowSuccessModal(true);
        })
    }

    return {
        sellTransaction,
        showSuccessModal,
        setShowSuccessModal
    }
}