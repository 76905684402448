export const containerStyle = {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'column', 
    height: '100%'
  }
  
export const buttonStyle = {
    backgroundColor: '#181A20',
    fontSize: '14px', 
    color: '#FFF',
    borderRadius: '4px',
    border: '1px solid #181A20',
    display: 'flex',
    padding: '5px 7.5px',
    cursor: 'pointer',
    justifyContent: 'center',
    maxWidth: '130px'
}
  
export const leftStatus = {
    width: '12px', 
    height: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    flexShrink: 0,
    marginRight: '5px'
}

export const statusIconConnected = {
    height: '5px', width: '5px', 
    borderRadius: '50%', 
    marginTop: '1.5px', 
    backgroundColor: 'green'
}

export const statusIconDisconnected = {
    height: '5px', 
    width: '5px', 
    borderRadius: '50%', 
    marginTop: '1.5px', 
    backgroundColor: 'red'
}

export const rowStyle = {
    margin: '10px 0px',
    width: '500px',
    display: 'flex', 
    justifyContent: 'center', 
    borderRadius: '4px', 
    border: '1px solid #181A20', 
    padding: '10px'
}

export const inputStyle = {
    width: '50%', 
    backgroundColor: '#181A20',  
    border: '1px solid', 
    borderImageSlice: 1,  
    borderImageSource: 'linear-gradient(to left, #734ad5, #d53a9d', 
    color: '#fff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '10px 0px'
}

export const modalStyle = {
    position: 'absolute', 
    width: '500px', 
    height: '350px', 
    border: '1px solid #4206F1', 
    borderRadius: '4px', 
    top: '50%', left: '50%', 
    transform: 'translate(-50%, -50%)', 
    backgroundColor: 'black', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'column'
}

export const balanceModalStyle = {
    position: 'absolute', 
    width: '500px', 
    height: '350px', 
    border: '1px solid #4206F1', 
    borderRadius: '4px', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    backgroundColor: 'black'
}

export const modalHeaderStyle = {
    marginTop: '100px', 
    display: 'flex', 
    width: '100px', 
    alignItems: 'center', 
    justifyContent: 'center', 
    width: '100%'
}